<template>
  <div class="warehouse-detail">
    <div class="warehouse-detail mb-4" v-if="warehouse">
      <!-- <Breadcrumb :items="breadcrumb" /> -->
      <v-container class="relative ">
        <v-row dense class="align-self-center mb-4">
          <v-col cols="12" sm="4" xl="3">
            <v-card elevation="0" class="mb-3">
              <div class="pa-4 warehouse-detail-column">
                <h1>{{ warehouse.name }}</h1>
                <div class="mb-4">
                  {{ warehouse.address.address1 }}
                  {{
                    warehouse.address.addressNumber
                      ? ", " + warehouse.address.addressNumber
                      : null
                  }},<br />
                  {{ warehouse.address.city }} ({{
                    warehouse.address.province
                  }}) {{ warehouse.address.postalcode }}<br />
                  {{ warehouse.address.country.name }}
                </div>
                <div v-if="warehouse.metaData.warehouse_locator">
                  <strong>Telefono: </strong>
                  {{ warehouse.metaData.warehouse_locator.PHONE }}
                </div>
              </div>
            </v-card>
            <v-card elevation="0" class="mb-3">
              <div class="pa-4">
                <div
                  v-if="warehouse.metaData.warehouse_locator"
                  v-html="warehouse.metaData.warehouse_locator.HOURS"
                ></div>
              </div>
            </v-card>
            <v-card
              elevation="0"
              v-if="warehouse.address && warehouse.address.addressClass"
            >
              <div class="pa-4">
                <h4 class="font-weight-bold">Servizi attivi</h4>
                <img
                  v-for="service in warehouse.address.addressClass"
                  :src="service.iconSource"
                  :alt="service.description"
                  :title="service.description"
                  :key="service.name"
                  class="pdv-service-img"
                />
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="8" xl="9">
            <v-card elevation="0" class="maps-card">
              <GmapMap
                v-if="warehouse.warehouseId"
                ref="mapRef"
                :center="{
                  lat: warehouse.address.latitude,
                  lng: warehouse.address.longitude
                }"
                :zoom="15"
                :style="{ width: mapWidth, height: mapHeight }"
                :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  disableDefaultUI: false
                }"
              >
                <GmapMarker
                  :ref="warehouse.warehouseId"
                  :position="
                    google &&
                      new google.maps.LatLng(
                        warehouse.address.latitude,
                        warehouse.address.longitude
                      )
                  "
                  :clickable="true"
                  icon="/pdv/pin.png"
                />
              </GmapMap>
            </v-card>
          </v-col>
        </v-row>
        <v-btn class="secondary" block elevation="0" href="/page/punti-vendita/"
          >Torna all'elenco province</v-btn
        >
        <WordPressDialog
          v-if="
            warehouse.metaData.warehouse_info &&
              warehouse.metaData.warehouse_info.SHOW_POPUP_EX_RATE &&
              popupContent
          "
          auto="1"
          :title="popupContent.title.rendered"
          :content="popupContent.content.rendered"
        ></WordPressDialog>
      </v-container>
    </div>
  </div>
</template>
<style lang="scss">
.warehouse-detail {
  background-color: #f7f7fa;
  padding: 15px 0;
  .swiper-wrapper {
    justify-content: center;
  }
  .maps-card {
    padding: 15px;
  }
  .warehouse-list-list {
    height: 380px;
    overflow-y: auto;
  }
  .warehouse-detail-column {
    h1 {
      font-weight: 900;
      font-size: 30px;
      line-height: 2.5rem;
      padding-bottom: 12px;
    }
    font-size: 16px;
    line-height: 150%;
    color: var(--v-primary-base) !important;
  }
  .pdv-service-img {
    padding: 0 8px;
    width: 60px;
  }
}
</style>
<script>
import AddressService from "~/service/addressService";
// import Breadcrumb from "@/components/navigation/Breadcrumb";
import { gmapApi } from "vue2-google-maps";
import CmService from "~/service/cmService";
import clickHandler from "~/mixins/clickHandler";
import WordPressDialog from "@/components/wordpress/WordPressDialog.vue";

export default {
  name: "WarehouseDetail",
  // components: { Breadcrumb },
  components: { WordPressDialog },
  data() {
    return {
      warehouseList: [],
      warehouse: null,
      popupContent: null
    };
  },
  mixins: [clickHandler],
  computed: {
    google: gmapApi,
    mapWidth() {
      return "auto";
    },
    mapHeight() {
      return "480px";
    },
    breadCrumbs() {
      let breadCrumbs = [
        { to: { name: "Home" }, text: "Homepage", exact: true },
        { to: { name: "WarehouseList" }, text: "Punti Vendita", exact: true }
      ];
      if (this.warehouse) {
        breadCrumbs.push({
          to: {
            name: "WarehouseDetail",
            params: {
              warehouseId: this.warehouse.warehouseId
            }
          },
          text: this.warehouse.name,
          exact: true
        });
      }
      return breadCrumbs;
    }
  },
  methods: {
    async findWarehouse() {
      if (this.warehouseList.length == 0) {
        let data = await AddressService.findWarehouse({
          region_id: this.selectedRegionId
        });
        this.warehouseList = data.warehouses;
      }
      this.warehouse = this.warehouseList.find(
        element => element.warehouseId == this.$route.params.warehouseId
      );
    },
    async getPopupcontent() {
      await CmService.getUxBlocks("popup-cambio-ebsn").then(
        data => (this.popupContent = data[0])
      );
    }
  },
  mounted() {
    this.findWarehouse();
    this.getPopupcontent();
  },
  watch: {
    $route() {
      this.findWarehouse();
    }
  }
};
</script>
