var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"warehouse-detail"},[(_vm.warehouse)?_c('div',{staticClass:"warehouse-detail mb-4"},[_c('v-container',{staticClass:"relative"},[_c('v-row',{staticClass:"align-self-center mb-4",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4","xl":"3"}},[_c('v-card',{staticClass:"mb-3",attrs:{"elevation":"0"}},[_c('div',{staticClass:"pa-4 warehouse-detail-column"},[_c('h1',[_vm._v(_vm._s(_vm.warehouse.name))]),_c('div',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.warehouse.address.address1)+" "+_vm._s(_vm.warehouse.address.addressNumber ? ", " + _vm.warehouse.address.addressNumber : null)+","),_c('br'),_vm._v(" "+_vm._s(_vm.warehouse.address.city)+" ("+_vm._s(_vm.warehouse.address.province)+") "+_vm._s(_vm.warehouse.address.postalcode)),_c('br'),_vm._v(" "+_vm._s(_vm.warehouse.address.country.name)+" ")]),(_vm.warehouse.metaData.warehouse_locator)?_c('div',[_c('strong',[_vm._v("Telefono: ")]),_vm._v(" "+_vm._s(_vm.warehouse.metaData.warehouse_locator.PHONE)+" ")]):_vm._e()])]),_c('v-card',{staticClass:"mb-3",attrs:{"elevation":"0"}},[_c('div',{staticClass:"pa-4"},[(_vm.warehouse.metaData.warehouse_locator)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.warehouse.metaData.warehouse_locator.HOURS)}}):_vm._e()])]),(_vm.warehouse.address && _vm.warehouse.address.addressClass)?_c('v-card',{attrs:{"elevation":"0"}},[_c('div',{staticClass:"pa-4"},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v("Servizi attivi")]),_vm._l((_vm.warehouse.address.addressClass),function(service){return _c('img',{key:service.name,staticClass:"pdv-service-img",attrs:{"src":service.iconSource,"alt":service.description,"title":service.description}})})],2)]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"8","xl":"9"}},[_c('v-card',{staticClass:"maps-card",attrs:{"elevation":"0"}},[(_vm.warehouse.warehouseId)?_c('GmapMap',{ref:"mapRef",style:({ width: _vm.mapWidth, height: _vm.mapHeight }),attrs:{"center":{
                lat: _vm.warehouse.address.latitude,
                lng: _vm.warehouse.address.longitude
              },"zoom":15,"options":{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUI: false
              }}},[_c('GmapMarker',{ref:_vm.warehouse.warehouseId,attrs:{"position":_vm.google &&
                    new _vm.google.maps.LatLng(
                      _vm.warehouse.address.latitude,
                      _vm.warehouse.address.longitude
                    ),"clickable":true,"icon":"/pdv/pin.png"}})],1):_vm._e()],1)],1)],1),_c('v-btn',{staticClass:"secondary",attrs:{"block":"","elevation":"0","href":"/page/punti-vendita/"}},[_vm._v("Torna all'elenco province")]),(
          _vm.warehouse.metaData.warehouse_info &&
            _vm.warehouse.metaData.warehouse_info.SHOW_POPUP_EX_RATE &&
            _vm.popupContent
        )?_c('WordPressDialog',{attrs:{"auto":"1","title":_vm.popupContent.title.rendered,"content":_vm.popupContent.content.rendered}}):_vm._e()],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }